/* eslint-disable react-hooks/exhaustive-deps */
import i18next from "i18next";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { getClientIP } from "../../components/MyInvestment/OverseasPortfolio/OverseasPortfolioTable";
import { Disclaimer } from "../../components/ResearchDisclaimer";
import YourMissionDialog from "../../components/YourMission/YourMissionDialog";
import { APP_ROUTE } from "../../const/path";
import { BTFLevel } from "../../enum/rewards";
import { getErrorMessageGetDataFail } from "../../helper/modal.message";
import SSOSTTLink from "../../helper/sso.stt.link";
import { useFeatureToggle } from "../../hooks/features";
import { isThaiLanguage } from "../../i18n/helper";
import { IDisclaimer } from "../../interfaces/cms";
import { IHandleModal } from "../../interfaces/handle.modal";
import { IMenu, ISideBarMenu } from "../../interfaces/menu";
import {
  IAccountInfo,
  IShortcut,
  IUserInfo,
  IUserRewards,
} from "../../interfaces/user";
import MissionService, { IMissions } from "../../services/ats.service";
import CMSService from "../../services/cms.service";
import DisclaimerService from "../../services/disclaimer.service";
import { RewardsService } from "../../services/rewards.service";
import UserService from "../../services/user.service";
import { useHandleAPIStateContext } from "../handle-api-state";
import {
  getAuthorizedEFormSubMenu,
  getAuthorizedEReportSubMenu,
  getAuthorizedInvestmentToolsSubMenu,
  getAuthorizedMenu,
  getAuthorizedMyAccountSubMenu,
  shouldGetAccountInfo,
} from "./helper";

interface IUserContext {
  accountInfo: IAccountInfo;
  setGetDataEror: (getDataError: IHandleModal) => void;
  setGettingData: (gettingData: boolean) => void;
  sideBarMenu: () => ISideBarMenu[];
  investmentToolsSubMenu: () => {
    digitalServices: IMenu[];
    mutualFundsTradningPlatform: IMenu[];
    partnerTools: IMenu[];
    overseaTradingPlatform: IMenu[];
  };
  myAccountSubMenu: () => {
    myCorner: IMenu[];
    accountManagement: IMenu[];
  };
  eReportSubMenu: () => {
    eReport: boolean;
    contractNote: boolean;
  };
  onConfirmAdvanceShortcuts: (shortcuts: IShortcut[]) => void;
  userInfo: IUserInfo;
  ssoSTTLink: SSOSTTLink;
  eFormSubMenu: {
    requestReports: IMenu[];
    changePersonalData: IMenu[];
    cashStockTransactions: IMenu[];
  };
  userRewards: IUserRewards;
  missions: IMissions[];
}

export const initAccountInfo = {
  isItp: false,
  isDeriv: false,
  isFund: false,
  isDrx: false,
  custCodes: [],
  accounts: [],
  username: "",
};

export const initUserInfo = {
  username: "",
  userref: "",
  shortcuts: [],
  specialMenu: [],
};

export const initUserRewards = {
  customerNameTH: "",
  customerNameEN: "",
  accountNo: "",
  totalPoints: 0,
  accounts: [],
  btfLevel: BTFLevel.NO_ACCOUNT,
  btfTradingVolume: 0,
  asOfDate: new Date(),
  expiryPoints: 0,
  pointExpirationDate: new Date(),
};

const UserContext = createContext<IUserContext>({
  setGetDataEror: () => {},
  setGettingData: () => {},
  sideBarMenu: () => [],
  investmentToolsSubMenu: () => ({
    digitalServices: [],
    mutualFundsTradningPlatform: [],
    partnerTools: [],
    overseaTradingPlatform: [],
  }),
  myAccountSubMenu: () => ({ myCorner: [], accountManagement: [] }),
  eReportSubMenu: () => ({ eReport: false, contractNote: false }),
  onConfirmAdvanceShortcuts: () => {},
  userInfo: { ...initUserInfo },
  ssoSTTLink: {} as any,
  eFormSubMenu: {
    requestReports: [],
    changePersonalData: [],
    cashStockTransactions: [],
  },
  accountInfo: { ...initAccountInfo },
  userRewards: { ...initUserRewards },
  missions: [],
});

export const UserContextWrapper = ({ children }: { children: JSX.Element }) => {
  const userService = new UserService();
  const cmsService = new CMSService();
  const rewardsService = new RewardsService();
  const disclaimerService = new DisclaimerService();

  const history = useHistory();
  const { t } = useTranslation();
  const {
    setGetDataEror,
    setGettingData,
  }: {
    setGettingData: (gettingData: boolean) => void;
    setGetDataEror: (getDataError: IHandleModal) => void;
  } = useHandleAPIStateContext();
  const [accountInfo, setAccountInfo] = useState<IAccountInfo>({
    ...initAccountInfo,
  });
  const [ssoSTTLink, setssoSTTLink] = useState<SSOSTTLink>();
  const [userInfo, setUserInfo] = useState<IUserInfo>({ ...initUserInfo });
  const [disclaimerToBeAccept, setDisclaimerToBeAccept] =
    useState<IDisclaimer>();
  const [ip, setIP] = useState("");

  const [isShowYourMissionPopup, setShowYourMissionPopup] =
    useState<boolean>(false);

  const [missions, setMissions] = useState<IMissions[]>([]);
  const [userRewards, setUserRewards] = useState<IUserRewards>({
    ...initUserRewards,
  });

  useEffect(() => {
    if (shouldGetAccountInfo(history.location.pathname, accountInfo))
      getAccountInfo();
    fetchUserInfo();
    getClientIP().then(setIP);
  }, [history.location.pathname]);

  useEffect(() => {
    const ssoSTTLink = new SSOSTTLink({ userref: userInfo!.userref });
    setssoSTTLink(ssoSTTLink);
  }, [userInfo, i18next.language]);

  useEffect(() => {
    if (accountInfo.username) {
      getMissions();
    }
  }, [accountInfo.username]);

  const fetchUserInfo = () =>
    userService.getUserInfo().then((userInfo) => {
      setUserInfo(userInfo);
      fetchUserAcceptDisclaimerLog();
    });

  const fetchUserAcceptDisclaimerLog = async () => {
    await disclaimerService
      .getDisclaimerToBeAccept()
      .then((data) => setDisclaimerToBeAccept(data));
  };

  const fetchUserRewards = async (username: string) => {
    try {
      const userRewardResponse = await rewardsService.getUserRewards(username);
      setUserRewards(userRewardResponse);
    } catch (error) {
      setGetDataEror(getErrorMessageGetDataFail(t));
    }
  };

  const getAccountInfo = () => {
    userService
      .getAccountInfo()
      .then((accInfo) => {
        setAccountInfo(accInfo);
        fetchUserRewards(accInfo.custCodes[0]);
      })
      .catch((e) => {
        if (e?.response?.status !== 401)
          setGetDataEror(getErrorMessageGetDataFail(t));
      });
  };

  const getMissions = async () => {
    const result = await new MissionService().getMissions();
    setShowYourMissionPopup(!!result.length && !isEservice);
    setMissions(result);
  };

  const featureToggle = useFeatureToggle();

  const sideBarMenu = () => getAuthorizedMenu(accountInfo, featureToggle);

  const investmentToolsSubMenu = () =>
    getAuthorizedInvestmentToolsSubMenu(accountInfo, userInfo);

  const myAccountSubMenu = () =>
    getAuthorizedMyAccountSubMenu(accountInfo, userInfo);

  const eReportSubMenu = () => getAuthorizedEReportSubMenu(accountInfo);

  const onConfirmAdvanceShortcuts = async (shortcuts: IShortcut[]) => {
    await userService
      .updateUserAdvanceShortcuts({ shortcutIdList: shortcuts })
      .then(() => fetchUserInfo())
      .catch(() => setGetDataEror(getErrorMessageGetDataFail(t)));
  };

  const eFormSubMenu: {
    requestReports: IMenu[];
    changePersonalData: IMenu[];
    cashStockTransactions: IMenu[];
  } = getAuthorizedEFormSubMenu(accountInfo, userInfo);

  const onAceeptDiscliamer = () => {
    cmsService.userAcceptDisclaimer(
      disclaimerToBeAccept!,
      userInfo.username,
      ip
    );
    setDisclaimerToBeAccept(undefined);
  };

  const closeYourMissionPopup = () => {
    setShowYourMissionPopup(false);
  };

  const goToDashboard = () => {
    setShowYourMissionPopup(false);
    history.replace(APP_ROUTE.DASHBOARD.path);
  };

  const isEservice = history.location.pathname === "/e-service";

  const shareState = {
    accountInfo,
    setGetDataEror,
    setGettingData,
    sideBarMenu,
    investmentToolsSubMenu,
    myAccountSubMenu,
    eReportSubMenu,
    onConfirmAdvanceShortcuts,
    userInfo,
    ssoSTTLink: ssoSTTLink!,
    eFormSubMenu,
    userRewards,
    missions,
  };

  return (
    <>
      {isShowYourMissionPopup && (
        <YourMissionDialog
          onClose={closeYourMissionPopup}
          backHome={isEservice ? closeYourMissionPopup : goToDashboard}
          missions={missions}
          isFullScreen={isEservice}
          replaceUrl={isEservice}
        />
      )}
      {disclaimerToBeAccept && (
        <Disclaimer
          title={
            isThaiLanguage()
              ? disclaimerToBeAccept.title_th
              : disclaimerToBeAccept.title_en
          }
          th={disclaimerToBeAccept.content_th}
          en={disclaimerToBeAccept.content_en}
          onAccept={onAceeptDiscliamer}
        />
      )}
      <UserContext.Provider value={shareState}>{children}</UserContext.Provider>
    </>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
