import { IFeaturesToggle } from "../hooks/features";
import httpClient from "./http";

export class FeatureToggleService {
  private http = httpClient();

  async getFeatureToggle() {
    return this.http
      .get<IFeaturesToggle>(`/feature-toggle`)
      .then((res) => res.data);
  }
}
