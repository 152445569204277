import { useEffect, useState } from "react";
import { FeatureToggleService } from "../services/features-toggle.service";
export interface IFeaturesToggle {
  consolidatePortFeat: boolean;
  redeemReward: boolean;
  openOverseaTradingAccount: boolean;
  rewards: boolean;
}

export const useFeatureToggle = () => {
  const [features, setFeatures] = useState<IFeaturesToggle>({
    consolidatePortFeat: false,
    redeemReward: false,
    openOverseaTradingAccount: false,
    rewards: false,
  });

  useEffect(() => {
    const fetchFeatureToggles = async () => {
      try {
        const toggle = await new FeatureToggleService().getFeatureToggle();
        setFeatures({
          consolidatePortFeat: toggle.consolidatePortFeat,
          redeemReward: toggle.redeemReward,
          openOverseaTradingAccount: toggle.openOverseaTradingAccount,
          rewards: toggle.rewards,
        });
      } catch (error) {
        console.error("Error fetching feature toggles:", error);
      }
    };

    fetchFeatureToggles();
  }, []);

  return features;
};
