import { Accounts } from "../components/Accounts";
import { NewShortcuts } from "../components/NewFavorite";
import { Points } from "../components/Points";
import { Research } from "../components/Research";
import YourMissionPanel from "../components/YourMission/YourMissionPanel";
import { useUserContext } from "../context/user/user.context";
import { useFeatureToggle } from "../hooks/features";

export const NewDashboard = () => {
  const { missions } = useUserContext();
  const featureToggle = useFeatureToggle();
  return (
    <div className="flex flex-col w-full p-3 xl:p-5 gap-y-5">
      <NewShortcuts />
      <YourMissionPanel missions={missions} />
      {featureToggle.rewards && <Points />}
      <Accounts />
      <Research />
    </div>
  );
};
