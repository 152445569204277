import { useState } from "react";
import { SVG_SEARCH } from "../../const/svg.icon";
import { searching } from "../../helper/search";
import { useFeatureToggle } from "../../hooks/features";
import { IKeywordsSearch } from "../../interfaces/keywords.search";
import SearchModal from "./SearchModal";

const IconSearch = () => {
  const [showSearchModal, setShowSearchModals] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [searchResult, setSearchResult] = useState<IKeywordsSearch[] | null>(
    null
  );
  const featureToggle = useFeatureToggle();

  const onSearch = async () => {
    setSearchResult(await searching(keyword, featureToggle));
  };

  const onCloseModal = () => {
    setShowSearchModals(false);
    setSearchResult(null);
    setKeyword("");
  };

  return (
    <>
      <div className="relative hidden h-10 text-xs rounded-md xl:flex w-96 bg-dbsv-neutral-slate-00">
        <input
          value={keyword}
          className="w-full p-2 my-1 ml-8 mr-4 bg-transparent outline-none placeholder-dbsv-neutral-slate-30"
          placeholder="Search by keywords"
          onChange={(e) => setKeyword(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onSearch();
              setShowSearchModals(true);
            }
          }}
        />
        <div
          id="iconSearch"
          className="absolute text-dbsv-neutral-slate-30 w-4 h-4 top-2.5 left-2.5"
        >
          {SVG_SEARCH}
        </div>
      </div>
      <div
        id="iconSearch"
        className="block cursor-pointer xl:hidden text-dbsv-neutral-slate-70"
        onClick={() => setShowSearchModals(true)}
      >
        {SVG_SEARCH}
      </div>
      <div className="absolute block">
        {showSearchModal && (
          <SearchModal
            searchResult={searchResult}
            onChange={(e) => setKeyword(e.target.value)}
            onClose={onCloseModal}
            onSearch={onSearch}
            searchText={keyword}
          />
        )}
      </div>
    </>
  );
};

export default IconSearch;
