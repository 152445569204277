import "rc-tooltip/assets/bootstrap.css";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import Banners from "./components/Banners";
import { Footer } from "./components/Footer";
import PrivateRoute from "./components/PrivateRoute";
import ShouldAcceptDisclaimerRoute from "./components/ShouldAcceptDisclaimerRoute";
import { SideBar } from "./components/SideBar";
import { TopBar } from "./components/TopBar";
import { APP_ROUTE } from "./const/path";
import { HandleAPIStateContextWrapper } from "./context/handle-api-state";
import { ResearchContextWrapper } from "./context/research";
import { RouteContextWrapper } from "./context/route.context";
import { UserContextWrapper } from "./context/user/user.context";
import { useFeatureToggle } from "./hooks/features";

function App() {
  useTranslation();
  const userFromStreaming = window.location.pathname.includes("/e-service");
  const featureToggle = useFeatureToggle();

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <RouteContextWrapper>
        <HandleAPIStateContextWrapper>
          {userFromStreaming ? (
            <UserContextWrapper>
              <>
                <Route
                  path={APP_ROUTE.E_SERVICE.path}
                  component={APP_ROUTE.E_SERVICE.component}
                />
                <ResearchContextWrapper>
                  <>
                    <Route
                      path={APP_ROUTE.E_SERVICE_RESEARCH.path}
                      component={APP_ROUTE.E_SERVICE_RESEARCH.component}
                    />
                    <Route
                      path={APP_ROUTE.E_SERVICE_RESEARCH_VIEW.path + "/:id"}
                      component={APP_ROUTE.E_SERVICE_RESEARCH_VIEW.component}
                    />
                    <Route
                      path={APP_ROUTE.E_SERVICE_RESEARCH_ARCHIVE.path}
                      component={APP_ROUTE.E_SERVICE_RESEARCH_ARCHIVE.component}
                    />
                  </>
                </ResearchContextWrapper>
                <Route
                  path={APP_ROUTE.E_SERVICE_E_STATEMENT.path}
                  component={APP_ROUTE.E_SERVICE_E_STATEMENT.component}
                />
                <Route
                  path={APP_ROUTE.E_SERVICE_CUSTOMER_HANDBOOK.path}
                  component={APP_ROUTE.E_SERVICE_CUSTOMER_HANDBOOK.component}
                />
              </>
            </UserContextWrapper>
          ) : (
            <>
              <Switch>
                <Route
                  path={APP_ROUTE.CONTRACT_NOTE_HELP.path}
                  component={APP_ROUTE.CONTRACT_NOTE_HELP.component}
                />
                <UserContextWrapper>
                  <>
                    <Banners />
                    <div
                      id="page-body"
                      className="w-screen h-screen overflow-x-hidden bg-dbsv-neutral-slate-00"
                    >
                      <div className="fixed z-20">
                        <SideBar />
                      </div>
                      <div className="flex flex-col justify-between h-full">
                        <div>
                          <div className="fixed z-20 w-full">
                            <TopBar />
                          </div>
                          <div className="pl-14 xl:pl-64 pt-14">
                            <ResearchContextWrapper>
                              <Switch>
                                <PrivateRoute
                                  path={APP_ROUTE.DASHBOARD.path}
                                  component={APP_ROUTE.DASHBOARD.component}
                                />
                                <PrivateRoute
                                  path={APP_ROUTE.DBSV_RESEARCH.path}
                                  component={APP_ROUTE.DBSV_RESEARCH.component}
                                />
                                <ShouldAcceptDisclaimerRoute
                                  path={APP_ROUTE.DBSV_RESEARCH_ARCHIVE.path}
                                  component={
                                    APP_ROUTE.DBSV_RESEARCH_ARCHIVE.component
                                  }
                                />
                                <ShouldAcceptDisclaimerRoute
                                  path={
                                    APP_ROUTE.DBSV_RESEARCH_PDF_VIEW.path +
                                    "/:id"
                                  }
                                  component={
                                    APP_ROUTE.DBSV_RESEARCH_PDF_VIEW.component
                                  }
                                />
                                <PrivateRoute
                                  path={APP_ROUTE.E_STATEMENT.path}
                                  component={APP_ROUTE.E_STATEMENT.component}
                                />
                                <PrivateRoute
                                  path={APP_ROUTE.MY_ACCOUNT.path}
                                  component={APP_ROUTE.MY_ACCOUNT.component}
                                />
                                <PrivateRoute
                                  path={APP_ROUTE.INVESTMENT_TOOLS.path}
                                  component={
                                    APP_ROUTE.INVESTMENT_TOOLS.component
                                  }
                                />
                                <PrivateRoute
                                  path={APP_ROUTE.E_FORMS.path}
                                  component={APP_ROUTE.E_FORMS.component}
                                />
                                <PrivateRoute
                                  path={APP_ROUTE.CUSTOMER_HANDBOOK.path}
                                  component={
                                    APP_ROUTE.CUSTOMER_HANDBOOK.component
                                  }
                                />
                                {featureToggle.rewards && (
                                  <PrivateRoute
                                    path={APP_ROUTE.DBSV_REWARDS.path}
                                    component={APP_ROUTE.DBSV_REWARDS.component}
                                  />
                                )}
                              </Switch>
                            </ResearchContextWrapper>
                            <Switch>
                              <Route
                                exact
                                path={APP_ROUTE.AUTH.path}
                                component={APP_ROUTE.AUTH.component}
                              />
                              <Route
                                path={APP_ROUTE.UNAUTHORIZED.path}
                                component={APP_ROUTE.UNAUTHORIZED.component}
                              />
                              <Route
                                path={APP_ROUTE.SSO_CALLBACK.path}
                                component={APP_ROUTE.SSO_CALLBACK.component}
                              />
                            </Switch>
                          </div>
                        </div>
                        <div className="w-full">
                          <Footer />
                        </div>
                      </div>
                    </div>
                  </>
                </UserContextWrapper>
              </Switch>
            </>
          )}
        </HandleAPIStateContextWrapper>
      </RouteContextWrapper>
    </BrowserRouter>
  );
}

export default App;
