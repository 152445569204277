import { E_FORMS } from "../../const/e-forms";
import { INVESTMENT_TOOLS } from "../../const/invesment-tool";
import { MY_ACCOUNT } from "../../const/my-account";
import { OUT_OF_CONTEXT_PATH, PATH_ROUTE } from "../../const/path";
import { IFeaturesToggle } from "../../hooks/features";
import { IMenu, ISideBarMenu, MainMenu, SubMenu } from "../../interfaces/menu";
import { IAccountInfo, IUserInfo } from "../../interfaces/user";
import { initAccountInfo } from "./user.context";

export const getAuthorizedMenu = (
  accountInfo: IAccountInfo,
  featureToggle: IFeaturesToggle
): ISideBarMenu[] => {
  const menu = [
    PATH_ROUTE.DASHBOARD,
    PATH_ROUTE.INVESTMENT_TOOLS,
    PATH_ROUTE.DBSV_RESEARCH,
    PATH_ROUTE.E_STATEMENT,
  ];
  if (accountInfo.isItp || accountInfo.isDeriv)
    menu.splice(1, 0, PATH_ROUTE.STREAMING);
  if (accountInfo.isItp) menu.push(PATH_ROUTE.E_FORMS);
  if (featureToggle.rewards) menu.push(PATH_ROUTE.DBSV_REWARDS);
  menu.push(PATH_ROUTE.CUSTOMER_HANDBOOK);
  menu.push(PATH_ROUTE.MY_ACCOUNT);
  return menu;
};

export const getAuthorizedInvestmentToolsSubMenu = (
  accountInfo: IAccountInfo,
  userInfo: IUserInfo
) => {
  const subMenu = {
    overseaTradingPlatform: [
      { menuDetail: INVESTMENT_TOOLS.ONLINE_TRADING_WEBSITE },
      { menuDetail: INVESTMENT_TOOLS.M_TRADING_TH },
    ],
    digitalServices: [
      { menuDetail: INVESTMENT_TOOLS.TECHNICAL_INSIGHT },
      { menuDetail: INVESTMENT_TOOLS.FUNDAMENTAL_INSIGHT },
      { menuDetail: INVESTMENT_TOOLS.BLOCK_TRADE },
    ] as IMenu[],
    mutualFundsTradningPlatform: [
      { menuDetail: INVESTMENT_TOOLS.WEALTHBOX },
    ] as IMenu[],
    partnerTools: [] as IMenu[],
  };
  if (accountInfo.isItp || accountInfo.isDeriv) {
    subMenu.partnerTools.push({
      menuDetail: INVESTMENT_TOOLS.EFIN_STOCK_PICKUP,
    });
    subMenu.partnerTools.push({ menuDetail: INVESTMENT_TOOLS.ASPEN });
    subMenu.partnerTools.push({ menuDetail: INVESTMENT_TOOLS.PLACE_ORDER });
  }
  if (userInfo.specialMenu && userInfo.specialMenu.length) {
    for (const specialMenu of userInfo.specialMenu) {
      if (specialMenu.mainMenu !== MainMenu.INVESTMENT_TOOLS) continue;
      if (specialMenu.subMenu === SubMenu.DBS_DIGITAL_SERVICES) {
        subMenu.digitalServices.push({
          menuDetail: specialMenu,
          isSpecialMenu: true,
        });
      } else if (
        specialMenu.subMenu === SubMenu.DBS_THAILAND_MOBILE_APPLICATION
      ) {
        subMenu.mutualFundsTradningPlatform.push({
          menuDetail: specialMenu,
          isSpecialMenu: true,
        });
      } else if (specialMenu.subMenu === SubMenu.PARTERS_TOOLS) {
        subMenu.partnerTools.push({
          menuDetail: specialMenu,
          isSpecialMenu: true,
        });
      }
    }
  }
  return subMenu;
};

export const getAuthorizedMyAccountSubMenu = (
  accountInfo: IAccountInfo,
  userInfo: IUserInfo
): {
  myCorner: IMenu[];
  accountManagement: IMenu[];
} => {
  const subMenu: {
    myCorner: IMenu[];
    accountManagement: IMenu[];
  } = {
    myCorner: [
      { menuDetail: MY_ACCOUNT.CHANGE_PASSWORD },
      { menuDetail: MY_ACCOUNT.CHANGE_PIN },
      { menuDetail: MY_ACCOUNT.FORGOT_PIN },
      { menuDetail: MY_ACCOUNT.SUIT_TEST },
    ],
    accountManagement: [],
  };

  if (accountInfo.isItp || accountInfo.isDeriv) {
    subMenu.myCorner.push({ menuDetail: MY_ACCOUNT.CHANGE_CONTRACT_INFO });
    subMenu.accountManagement.push({ menuDetail: MY_ACCOUNT.DEPOSIT_CASH });
    subMenu.accountManagement.push({ menuDetail: MY_ACCOUNT.WITHDRAW_CASH });
  }
  if (accountInfo.isItp) {
    subMenu.accountManagement.push(
      ...[
        { menuDetail: MY_ACCOUNT.DEPOSIT_STOCK },
        { menuDetail: MY_ACCOUNT.WITHDRAW_STOCK },
        { menuDetail: MY_ACCOUNT.CONVERT_STOCK },
        {
          menuDetail: MY_ACCOUNT.DIRECT_DEBIT_REGISTRATION,
        },
        {
          menuDetail: MY_ACCOUNT.CHANGE_DEFAULT_ACCOUNT,
        },
      ]
    );
  } else {
    subMenu.accountManagement.push({
      menuDetail: MY_ACCOUNT.DIRECT_DEBIT_REGISTRATION,
    });
  }
  if (userInfo.specialMenu && userInfo.specialMenu.length) {
    for (const specialMenu of userInfo.specialMenu) {
      if (specialMenu.mainMenu !== MainMenu.MY_ACCOUNT) continue;
      if (specialMenu.subMenu === SubMenu.MY_CORNER) {
        subMenu.myCorner.push({ menuDetail: specialMenu, isSpecialMenu: true });
      } else if (specialMenu.subMenu === SubMenu.ACCOUNT_MANAGEMENT) {
        subMenu.accountManagement.push({
          menuDetail: specialMenu,
          isSpecialMenu: true,
        });
      }
    }
  }

  return subMenu;
};

export const getAuthorizedEReportSubMenu = (
  accountInfo: IAccountInfo
): {
  eReport: boolean;
  contractNote: boolean;
} => {
  return {
    eReport: accountInfo.isItp,
    contractNote: accountInfo.isDeriv,
  };
};

export const getAuthorizedEFormSubMenu = (
  accountInfo: IAccountInfo,
  userInfo: IUserInfo
): {
  requestReports: IMenu[];
  changePersonalData: IMenu[];
  cashStockTransactions: IMenu[];
} => {
  const subMenu: {
    requestReports: IMenu[];
    changePersonalData: IMenu[];
    cashStockTransactions: IMenu[];
  } = {
    requestReports: [],
    changePersonalData: [],
    cashStockTransactions: [],
  };

  if (accountInfo.isItp || accountInfo.isDeriv) {
    subMenu.changePersonalData.push({
      menuDetail: E_FORMS.CHANGE_CONTRACT_INFO,
    });
    subMenu.cashStockTransactions.push({ menuDetail: E_FORMS.DEPOSIT_CASH });
    subMenu.cashStockTransactions.push({ menuDetail: E_FORMS.WITHDRAW_CASH });
  }
  if (accountInfo.isItp) {
    subMenu.requestReports.push({ menuDetail: E_FORMS.SECURITY_STATUS_REPORT });
    subMenu.cashStockTransactions.push({ menuDetail: E_FORMS.DEPOSIT_STOCK });
    subMenu.cashStockTransactions.push({ menuDetail: E_FORMS.WITHDRAW_STOCK });
    subMenu.cashStockTransactions.push({ menuDetail: E_FORMS.CONVERT_STOCK });
  }

  if (userInfo.specialMenu && userInfo.specialMenu.length) {
    for (const specialMenu of userInfo.specialMenu) {
      if (specialMenu.mainMenu !== MainMenu.E_FORMS) continue;
      if (specialMenu.subMenu === SubMenu.REQUEST_REPORTS) {
        subMenu.requestReports.push({
          menuDetail: specialMenu,
          isSpecialMenu: true,
        });
      } else if (specialMenu.subMenu === SubMenu.CHANGE_PERSONAL_DATA) {
        subMenu.changePersonalData.push({
          menuDetail: specialMenu,
          isSpecialMenu: true,
        });
      } else if (specialMenu.subMenu === SubMenu.CASH_STOCK_TRANSACTIONS) {
        subMenu.cashStockTransactions.push({
          menuDetail: specialMenu,
          isSpecialMenu: true,
        });
      }
    }
  }
  subMenu.cashStockTransactions.push({ menuDetail: E_FORMS.BTF_E_FORM });

  return subMenu;
};

export const shouldGetAccountInfo = (
  currentPath: string,
  accountInfo: IAccountInfo
) => {
  if (
    !Object.entries(OUT_OF_CONTEXT_PATH)
      .map((route) => route[1])
      .includes(currentPath)
  )
    return JSON.stringify(accountInfo) === JSON.stringify(initAccountInfo);
  return false;
};
